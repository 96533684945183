/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AccessToken,
  AdminEmailCheck,
  AdminEmailCheckResponse,
  AdminMagicLinkGenerate,
  AdminTokenObtainPair,
  AdminTokenRefresh,
  AdminV1LoginCreate200,
  AdminV1LoginRefreshCreate200,
  AdminV1LoginRefreshCreateParams,
  ChangePassword,
  MagicLinkAuthenticate,
  ResetPassword
} from '.././model'
import adminV1ChangePasswordCreateMutator from '../../shared/services/axios-client';
import adminV1EmailCheckCreateMutator from '../../shared/services/axios-client';
import adminV1LoginCreateMutator from '../../shared/services/axios-client';
import adminV1LoginMagicLinkCreateMutator from '../../shared/services/axios-client';
import adminV1LoginRefreshCreateMutator from '../../shared/services/axios-client';
import adminV1MagicLinkGenerateCreateMutator from '../../shared/services/axios-client';
import adminV1ResetPasswordCreateMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  /**
 * Change the password for the user.
 * @summary Change the password for the user.
 */
export const adminV1ChangePasswordCreate = (
    changePassword: ChangePassword,
 options?: SecondParameter<typeof adminV1ChangePasswordCreateMutator>,) => {
      return adminV1ChangePasswordCreateMutator<AccessToken>(
      {url: `/api/admin/v1/change-password/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: changePassword
    },
      options);
    }
  /**
 * Check if an email belongs to an admin user and return their authentication preference
 * @summary Check if an email belongs to an admin user and return their authentication preference
 */
export const adminV1EmailCheckCreate = (
    adminEmailCheck: AdminEmailCheck,
 options?: SecondParameter<typeof adminV1EmailCheckCreateMutator>,) => {
      return adminV1EmailCheckCreateMutator<AdminEmailCheckResponse>(
      {url: `/api/admin/v1/email-check/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminEmailCheck
    },
      options);
    }
  /**
 * Authenticate an admin user and get a JWT token.
 * @summary Obtain JWT Token for Admin
 */
export const adminV1LoginCreate = (
    adminTokenObtainPair: AdminTokenObtainPair,
 options?: SecondParameter<typeof adminV1LoginCreateMutator>,) => {
      return adminV1LoginCreateMutator<AdminV1LoginCreate200>(
      {url: `/api/admin/v1/login/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminTokenObtainPair
    },
      options);
    }
  /**
 * Authenticate an admin user using a magic link token
 * @summary Authenticate an admin user using a magic link token
 */
export const adminV1LoginMagicLinkCreate = (
    magicLinkAuthenticate: MagicLinkAuthenticate,
 options?: SecondParameter<typeof adminV1LoginMagicLinkCreateMutator>,) => {
      return adminV1LoginMagicLinkCreateMutator<AdminTokenObtainPair>(
      {url: `/api/admin/v1/login/magic-link`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: magicLinkAuthenticate
    },
      options);
    }
  /**
 * Refreshes an admin user's JWT token using a valid refresh token.
 * @summary Refresh JWT Token for Admin
 */
export const adminV1LoginRefreshCreate = (
    adminTokenRefresh: NonReadonly<AdminTokenRefresh>,
    params: AdminV1LoginRefreshCreateParams,
 options?: SecondParameter<typeof adminV1LoginRefreshCreateMutator>,) => {
      return adminV1LoginRefreshCreateMutator<AdminV1LoginRefreshCreate200>(
      {url: `/api/admin/v1/login/refresh/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminTokenRefresh,
        params
    },
      options);
    }
  /**
 * Generate and send a magic link to an admin user's email
 * @summary Generate and send a magic link to an admin user's email
 */
export const adminV1MagicLinkGenerateCreate = (
    adminMagicLinkGenerate: AdminMagicLinkGenerate,
 options?: SecondParameter<typeof adminV1MagicLinkGenerateCreateMutator>,) => {
      return adminV1MagicLinkGenerateCreateMutator<void>(
      {url: `/api/admin/v1/magic-link/generate/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminMagicLinkGenerate
    },
      options);
    }
  /**
 * Send a password reset email to the user.
 * @summary Send a password reset email to the user.
 */
export const adminV1ResetPasswordCreate = (
    resetPassword: ResetPassword,
 options?: SecondParameter<typeof adminV1ResetPasswordCreateMutator>,) => {
      return adminV1ResetPasswordCreateMutator<void>(
      {url: `/api/admin/v1/reset-password/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: resetPassword
    },
      options);
    }
  export type AdminV1ChangePasswordCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1ChangePasswordCreate>>>
export type AdminV1EmailCheckCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1EmailCheckCreate>>>
export type AdminV1LoginCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1LoginCreate>>>
export type AdminV1LoginMagicLinkCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1LoginMagicLinkCreate>>>
export type AdminV1LoginRefreshCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1LoginRefreshCreate>>>
export type AdminV1MagicLinkGenerateCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1MagicLinkGenerateCreate>>>
export type AdminV1ResetPasswordCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1ResetPasswordCreate>>>
