import type { LineItem } from "@/types";

export const ACTIVE_INVOICE_DISPLAY = "Active";
export const PAID_INVOICE_DISPLAY = "Paid";
export const PAST_DUE_INVOICE_DISPLAY = "Past Due";
export const DRAFT_INVOICE_DISPLAY = "Draft";
export const PARTIALLY_PAID_INVOICE_DISPLAY = "Partially Paid";
export const CANCELLED_INVOICE_DISPLAY = "Cancelled";
export const UNKNOWN_INVOICE_DISPLAY = "Unknown";

export const INVOICE_STATUSES_DISPLAY = [
  ACTIVE_INVOICE_DISPLAY,
  PAID_INVOICE_DISPLAY,
  PAST_DUE_INVOICE_DISPLAY,
  DRAFT_INVOICE_DISPLAY,
  PARTIALLY_PAID_INVOICE_DISPLAY,
  CANCELLED_INVOICE_DISPLAY,
  UNKNOWN_INVOICE_DISPLAY,
];

export const ACTIVE_INVOICE = "active";
export const PAID_INVOICE = "paid";
export const PAST_DUE_INVOICE = "past due";
export const DRAFT_INVOICE = "draft";
export const PARTIALLY_PAID_INVOICE = "partially paid";
export const CANCELLED_INVOICE = "cancelled";
export const UNKNOWN_INVOICE = "unknown";

export const INVOICE_STATUSES = [
  ACTIVE_INVOICE,
  PAID_INVOICE,
  PAST_DUE_INVOICE,
  DRAFT_INVOICE,
  PARTIALLY_PAID_INVOICE,
  CANCELLED_INVOICE,
  UNKNOWN_INVOICE,
];

export const BLANK_INVOICE_LINE_ITEM: LineItem = {
  itemName: "",
  itemPrice: 0,
  itemQuantity: 1,
  itemCategory: "",
};

export const CREATE_INVOICE_FORM_TITLE = "Create Invoice";
export const EDIT_INVOICE_FORM_TITLE = "Edit Invoice";

export const ACTIVE_INVOICE_FILTER = "active";
export const PAST_DUE_INVOICE_FILTER = "past_due";
export const PAID_INVOICE_FILTER = "paid";
export const PARTIALLY_PAID_INVOICE_FILTER = "partially_paid";
export const ALL_INVOICE_FILTER = null;

export const VALID_INVOICE_FILTERS = [
  ACTIVE_INVOICE_FILTER,
  PAST_DUE_INVOICE_FILTER,
  PAID_INVOICE_FILTER,
  PARTIALLY_PAID_INVOICE_FILTER,
  ALL_INVOICE_FILTER,
];

export const ACTIVE_INVOICE_FILTER_LABEL = "Status: Active";
export const PAST_DUE_INVOICE_FILTER_LABEL = "Status: Past Due";
export const PAID_INVOICE_FILTER_LABEL = "Status: Paid";
export const PARTIALLY_PAID_INVOICE_FILTER_LABEL = "Status: Partially Paid";
export const ALL_INVOICE_FILTER_LABEL = "Status: All";

export const INVOICE_FILTER_OPTIONS = [
  {
    value: ALL_INVOICE_FILTER,
    label: ALL_INVOICE_FILTER_LABEL,
  },
  { value: ACTIVE_INVOICE_FILTER, label: ACTIVE_INVOICE_FILTER_LABEL },
  { value: PAST_DUE_INVOICE_FILTER, label: PAST_DUE_INVOICE_FILTER_LABEL },
  { value: PAID_INVOICE_FILTER, label: PAID_INVOICE_FILTER_LABEL },
  {
    value: PARTIALLY_PAID_INVOICE_FILTER,
    label: PARTIALLY_PAID_INVOICE_FILTER_LABEL,
  },
];
